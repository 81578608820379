import React from 'react'
import PropTypes from 'prop-types';

import { graphql, Link } from "gatsby"

import Layout from 'components/Layout'
import ContactMe from 'components/ContactMe'
import Hero from 'components/Hero'
import SEO from "components/seo"
import Container from 'components/Container'
import Img from "gatsby-image"

const IndexPage = ({data}) => {
  const themes = data.allMarkdownRemark.edges
  return (
    <Layout pageName="home">
      <SEO title="Home" />
      <Hero 
        title="RESPONSIVE <br/> OSTICKET TEMPLATES"
        description="Here you can find beautiful custom designed <u>front-end</u> themes for your osTicket support system. Give a facelift to your osTicket site. "
      ></Hero>

      {
        themes.map((theme,i) => {
          let themePic = theme.node.frontmatter.image.childImageSharp.fluid
          return(
            <div key={i} className="theme">
            <Container>
            <div className="entry">
                    <div className="entry-pic">
                    <Link to={theme.node.fields.slug}>
                    <Img fluid={themePic}
                    alt={theme.node.frontmatter.title}
                    />
                  </Link>
                    </div>
                    <div className="entry-content">
                        <h2 className="entry-title">
                        <Link to={theme.node.fields.slug}>
                          {theme.node.frontmatter.title}
                          </Link>
                        </h2>
                        <h4> osTicket Version - {theme.node.frontmatter.version}</h4>
                        <div className="entry-text" dangerouslySetInnerHTML={{__html: theme.node.html}}></div>
                        <div className="entry-footer">
                            <button> 
                                <a href={theme.node.frontmatter.preview} target="_blank" rel="noopener noreferrer"> Preview </a>
                            </button>
                            <button> 
                                <a href={theme.node.frontmatter.product} target="_blank" rel="noopener noreferrer"> Purchase-$40</a>
                            </button>
                        </div>
                    </div>

                </div>
                </Container>
        </div>
          )
        })
      }
      <ContactMe></ContactMe>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};


export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
    edges {
      node {
        
        fields {
          slug
          }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 740) {
              ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          preview
          product
          title
          version
        }
        html
      }
    }
  }

  }
`